
// make a function is_downtown_bound_courthouse
export function is_downtown_bound_courthouse(station) {
  const downtown_bound_courthouse = new Set();
  // silver and orange lines
  downtown_bound_courthouse.add('Downtown Largo');
  downtown_bound_courthouse.add('Morgan Boulevard');
  downtown_bound_courthouse.add('Addison Road-Seat Pleasant');
  downtown_bound_courthouse.add('Capitol Heights');
  downtown_bound_courthouse.add('Benning Road');
  downtown_bound_courthouse.add('Stadium-Armory');
  downtown_bound_courthouse.add('Potomac Ave');
  downtown_bound_courthouse.add('Eastern Market');
  downtown_bound_courthouse.add('Capitol South');
  downtown_bound_courthouse.add('Federal Center SW');
  downtown_bound_courthouse.add('L\'Enfant Plaza');
  downtown_bound_courthouse.add('Smithsonian');
  downtown_bound_courthouse.add('Federal Triangle');
  downtown_bound_courthouse.add('Metro Center');
  downtown_bound_courthouse.add('McPherson Square');
  downtown_bound_courthouse.add('Farragut West');
  downtown_bound_courthouse.add('Foggy Bottom-GWU');
  downtown_bound_courthouse.add('Rosslyn');

  // orange line extras
  downtown_bound_courthouse.add('New Carrollton');
  downtown_bound_courthouse.add('NewCrlton'); // deal with WMATA API typo
  downtown_bound_courthouse.add('Landover');
  downtown_bound_courthouse.add('Cheverly');
  downtown_bound_courthouse.add('Deanwood');
  downtown_bound_courthouse.add('Minnesota Ave');
  return downtown_bound_courthouse.has(station);
}

export function is_downtown_bound_mclean(station) {
  const uptown_bound_mclean = new Set();
  // silver line
  uptown_bound_mclean.add('Tysons');
  uptown_bound_mclean.add('Greensboro');
  uptown_bound_mclean.add('Spring Hill');
  uptown_bound_mclean.add('Wiehle-Reston East');
  uptown_bound_mclean.add('Reston Town Center');
  uptown_bound_mclean.add('Herndon');
  uptown_bound_mclean.add('Innovation Center');
  uptown_bound_mclean.add('Washington Dulles International Airport');
  uptown_bound_mclean.add('Loudoun Gateway');
  uptown_bound_mclean.add('Ashburn');

  return !uptown_bound_mclean.has(station);
}