import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import SubwayIcon from '@mui/icons-material/Subway';
import MenuIcon from '@mui/icons-material/Menu';

import { Link } from 'react-router-dom';

function TopbarWithDrawer() {
    const [isOpen, setIsOpen] = useState(false);

    const [topBarTitle, setTopBarTitle] = useState('courthouse schedule');

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsOpen(open);
    };

    const list = () => (
        <div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            style={{ width: 250 }}
        >
            <List>
                {['courthouse', 'mclean'].map((text, index) => (
                    <ListItem 
                    button key={text} 
                    component={Link} 
                    to={`/${text.toLowerCase()}`}
                    onClick={() =>
                        setTopBarTitle(`${text} schedule`)
                    
                    }>
                        <ListItemIcon>
                            <SubwayIcon/>
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {topBarTitle}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor='left'
                open={isOpen}
                onClose={toggleDrawer(false)}
            >
                {list()}
            </Drawer>
        </div>
    );
}

export default TopbarWithDrawer;
