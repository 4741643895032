import React from 'react';
import './index.css';
import CourtHouse from './CourtHouse';
import McLean from './McLean';
import CSSBaseline from '@mui/material/CssBaseline';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TopbarWithDrawer from './TopbarWithDrawer';
import LocationDisplay from './LocationDisplay';

const App = () => {

    return (
        <>
            <BrowserRouter>
                <CSSBaseline />
                <TopbarWithDrawer />
                <Routes>
                    <Route path="/" element={<CourtHouse/>}/>
                    <Route path="/courthouse" element={<CourtHouse/>}/>
                    <Route path="/mclean" element={<McLean/>} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;