import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Container } from '@mui/material';

import { is_downtown_bound_mclean } from './train-direction.js'
import { Outlet } from 'react-router-dom';
//import ShowTime from './ShowTime.js';

function McLean() {
  const [metroData, setMetroData] = useState(null)
  
  useEffect(() => {
    const fetchMetroData = () => {
      fetch('https://api.wmata.com/StationPrediction.svc/json/GetPrediction/N01', {method:'GET',
        headers: {
          'api_key' : 'bbae2a29b9b84e48b3f2ea85f01521de'
        }
      })
        .then( response => {
          if (response.ok) {
            return response.json()
          }
          throw new Error('Network response was not ok.');
        })
        .then( data => {
          setMetroData(data)
        })
        .catch( error => {
          console.error('There has been a problem with your fetch operation:', error)
        });
    };

    fetchMetroData();

    const intervalId = setInterval(fetchMetroData, 30000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
    <Outlet/>
      {/* fill container and all empty space with rgb(189,189,189)*/}
      <Container sx={{ backgroundColor: 'rgb(189,189,189)', height: '100vh' }}>
      {/* add padding and white background color and rounded edges. Make top padding equal to all other sides. Add vertical margin*/}
      <Container className='train-table' sx={{ backgroundColor: 'white', borderRadius: 5, padding: 2, marginY: 2 }}>
      <Typography variant='h5'>
        Downtown Bound
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Line</TableCell>
              <TableCell>Car</TableCell>
              <TableCell>Dest</TableCell>
              <TableCell>Min</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {metroData && metroData.Trains.map((train, index) => (
              is_downtown_bound_mclean(train.DestinationName) &&
              <TableRow 
              key={index}
              >
                <TableCell>{train.Line}</TableCell>
                <TableCell>{train.Car}</TableCell>
                <TableCell>{train.DestinationName}</TableCell>
                <TableCell>{train.Min}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Container>
      {/* add padding and white background color and rounded edges. Make top padding equal to all other sides*/}
      <Container className='train-table' sx={{ backgroundColor: 'white', borderRadius: 5, padding: 2, marginY: 2 }}>
      <Typography variant='h5'>
        Uptown Bound
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Line</TableCell>
              <TableCell>Car</TableCell>
              <TableCell>Dest</TableCell>
              <TableCell>Min</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {metroData && metroData.Trains.map((train, index) => (
              !is_downtown_bound_mclean(train.DestinationName) &&
              <TableRow 
              key={index}
              >
                <TableCell>{train.Line}</TableCell>
                <TableCell>{train.Car}</TableCell>
                <TableCell>{train.DestinationName}</TableCell>
                <TableCell>{train.Min}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Container>
      </Container>
      
    </>
  )
}

export default McLean;
